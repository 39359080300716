
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/progenetix-cohorts/arraymap",
      function () {
        return require("private-next-pages/progenetix-cohorts/arraymap.js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/progenetix-cohorts/arraymap"])
      });
    }
  